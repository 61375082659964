import React, { useContext } from "react";
import styled from "styled-components";
import logo from "../ivp_firminis_spalvotas.png";
import { LocalPhone, Email } from "@emotion-icons/material";
import { LanguageContext } from "../language";
import Logo from "./logo";

const Container = styled.div`
  display: block;
  /* flex-direction: column; */
  margin: 0;
  height: 320px;
  padding: 0 30px 0 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/obuoliai-narrow.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin: 10px 0 0 0;
    padding: 0 10px 10px 10px;
  }
  width: 100%;
`;

const HeaderMiddle = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 200px;
  width: 100%;
  height: auto;
`;

const HeaderText = styled.div`
  color: #fff;
  font-size: 14px;
  font-family: system-ui, sans-serif;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 576px) {
    margin: 10px 20px 0px 0px;
  }
  @media only screen and (min-width: 768px) {
    margin: 10px 30px 0px 0px;
  }
  @media only screen and (min-width: 992px) {
    margin: 10px 80px 0px 0px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 10px 150px 0px 0px;
  }
  @media only screen and (min-width: 1400px) {
    margin: 10px 200px 0px 0px;
  }
`;

const Heading = styled.div`
  color: #fff;
  text-align: left;
  font-size: 2em;
  font-weight: 400;

  @media only screen and (max-width: 575px) {
    font-size: 4vw;
    letter-spacing: 1px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 4.2vw;
    letter-spacing: 1px;
    margin-top: 9%;
  }
  @media only screen and (min-width: 768px) {
    font-size: 4.5vw;
    letter-spacing: 1px;
    margin-top: 6%;
  }
  @media only screen and (min-width: 992px) {
    font-size: 4vw;
    letter-spacing: 6px;
    margin-top: 4%;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 4vw;
    letter-spacing: 8px;
    margin-top: 3%;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 3vw;
    letter-spacing: 16px;
    margin-top: 2%;
  }
`;

const LanguageBtn = styled.button`
  border: none;
  color: ${props => props.active ? "#cca876;" : "#9b9b9b;"};
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  height: 14px;
  width: 20px;
  &:hover {
    opacity: 80%;
  }
`;

export default function Header() {
  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <Container $query="sm">
      <HeaderTop>
        <Logo src={logo}></Logo>
        <HeaderText>
          <LocalPhone size={20} color="#cca876" /> (+370 5) 2197601
        </HeaderText>
        <HeaderText>
          <Email size={20} color="#cca876" /> info@ivp.lt
        </HeaderText>
        <HeaderText>
          <LanguageBtn
            src={language === "lt" ? "/en.png" : "/lt.png"}
            onClick={() => updateLanguage(language === "lt" ? "en" : "lt")}
            title={language === "lt" ? "Switch to English" : "Perjungti į Lietuvių kalbą"}
          />
        </HeaderText>
      </HeaderTop>
      <HeaderMiddle>
        <Heading>APSIKEITIMO DOKUMENTAIS SISTEMA </Heading>
      </HeaderMiddle>
    </Container>
  );
}
